@font-face {
  font-family: Digital Numbers;
  src: url("Digital-Numbers.aca489d2.ttf");
}

html {
  font-family: MD IO;
  font-style: normal;
  font-weight: 400;
}

body {
  color: #fff;
  background: #000;
  max-width: 1200px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0000001a;
  border-radius: 4px;
}

img {
  display: block;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  overflow-y: scroll;
}

.content-retract {
  padding: 0 32px;
}

.merge-page .header {
  box-sizing: border-box;
  background: #000;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  display: flex;
}

.merge-page .header .left .logo {
  vertical-align: bottom;
  height: 40px;
  display: inline-block;
}

.merge-page .header .middle {
  justify-content: center;
  align-items: center;
  display: flex;
}

.merge-page .header .middle .faqs {
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  margin-left: 48px;
  margin-right: 14px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
}

.merge-page .header .right {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.merge-page .header .right .link-con {
  align-items: center;
  margin-left: 60px;
  display: flex;
}

.merge-page .header .right .link-con .item {
  margin-left: 20px;
}

.merge-page .header .right .link-con .item svg {
  height: 13px;
}

.merge-page .header .nav-panel {
  display: none;
}

.merge-page .sm-show {
  display: none !important;
}

.merge-page .merge-content .merge-title {
  color: #fff;
  margin: 32px 0;
  font-size: 56px;
  font-weight: 700;
}

.merge-page .merge-content .merge-label {
  color: #fff;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
}

.merge-page .merge-content .merge-label a {
  color: #797bff;
  text-decoration: none;
}

.merge-page .merge-content .merge-select-con, .merge-page .merge-content .merge-succ-con {
  margin-top: 40px;
}

.merge-page .merge-content .merge-select-con .item, .merge-page .merge-content .merge-succ-con .item {
  margin-right: 24px;
}

.merge-page .merge-content .merge-select-con .item .hero-img, .merge-page .merge-content .merge-succ-con .item .hero-img {
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.merge-page .merge-content .merge-select-con .item .hero, .merge-page .merge-content .merge-succ-con .item .hero {
  color: #fff;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 600;
}

.merge-page .merge-content .merge-select-con .item .elite, .merge-page .merge-content .merge-succ-con .item .elite {
  color: #74f3f5;
}

.merge-page .merge-content .merge-select-con .item .epic, .merge-page .merge-content .merge-succ-con .item .epic {
  color: #aa18dd;
}

.merge-page .merge-content .merge-select-con .item .legendary, .merge-page .merge-content .merge-succ-con .item .legendary {
  color: #d66513;
}

.merge-page .merge-content .merge-select-con .item .ar, .merge-page .merge-content .merge-succ-con .item .ar {
  margin-left: 24px;
}

.merge-page .merge-content .merge-select-con .item .no-img, .merge-page .merge-content .merge-succ-con .item .no-img, .merge-page .merge-content .merge-select-con .item .has-img, .merge-page .merge-content .merge-succ-con .item .has-img {
  cursor: pointer;
  background: #22252d;
  border: 2px dashed #31343f;
  border-radius: 12px;
  width: 240px;
  height: 240px;
  margin-top: 8px;
}

.merge-page .merge-content .merge-select-con .item .has-img, .merge-page .merge-content .merge-succ-con .item .has-img {
  border-color: #0000;
  position: relative;
}

.merge-page .merge-content .merge-select-con .item .has-img img, .merge-page .merge-content .merge-succ-con .item .has-img img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.merge-page .merge-content .merge-select-con .item .has-img .close-btn, .merge-page .merge-content .merge-succ-con .item .has-img .close-btn {
  position: absolute;
  top: -8px;
  right: -8px;
}

.merge-page .merge-content .merge-succ-con .right-con {
  min-width: 258px;
  margin-top: 24px;
  margin-left: 76px;
}

.merge-page .merge-content .merge-succ-con .right-con .right-title {
  color: #fff;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.merge-page .merge-content .merge-succ-con .right-con #attr-con {
  width: 100%;
}

.merge-page .merge-content .merge-succ-con .right-con #attr-con .attr {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  display: flex;
}

.merge-page .merge-content .merge-succ-con .right-con #attr-con .attr .label {
  color: #8c8ca2;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 144%;
}

.merge-page .merge-content .merge-succ-con .right-con #attr-con .attr .value {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}

.merge-page .merge-content .btn-group {
  margin-top: 48px;
}

.merge-page .merge-content .btn-group .button {
  cursor: pointer;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  height: 48px;
  margin-right: 32px;
  display: flex;
}

.merge-page .merge-content .btn-group .button:active {
  opacity: .85;
}

.merge-page .merge-content .btn-group .button.merge {
  color: #fff;
  background: #797bff;
  width: 240px;
  box-shadow: 0 6px 2px -4px #0e0e2c1a, inset 0 -1px #0e0e2c66;
}

.merge-page .merge-content .btn-group .button.merge.disable {
  opacity: .4;
  cursor: not-allowed;
}

.merge-page .merge-content .btn-group .button.reset {
  color: #797bff;
  background: #797bff33;
  width: 100px;
  box-shadow: 0 6px 2px -4px #0e0e2c1a, inset 0 -1px #0e0e2c66;
}

#waitStatus {
  text-align: left;
  background: #00000080;
  border-radius: 10px;
  width: 568px;
  margin-top: 58px;
  padding: 10px 20px;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}

.spinner {
  position: fixed !important;
}

#dialog {
  z-index: 1000;
  box-sizing: border-box;
  background: #00000080;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

#dialog .content {
  background: #4a4a68;
  width: 360px;
  padding: 24px;
}

#dialog .content .label {
  font-family: "MD IO 0.4";
  font-style: italic;
  font-weight: 400;
  line-height: 144%;
}

#dialog .content .btn-con {
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  display: flex;
}

#dialog .content .btn-con .btn {
  color: #fff;
  cursor: pointer;
  background: #797bff;
  border-radius: 8px;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
}

#dialog .content .btn-con .btn.cancel-btn {
  color: #797bff;
  background: #797bff33;
  margin-right: 24px;
}

.hidden {
  display: none !important;
}

@media screen and (width <= 768px) {
  .merge-page .sm-hide {
    display: none !important;
  }

  .merge-page .sm-show {
    display: block !important;
  }

  .merge-page .content, .merge-page body, .merge-page html {
    max-width: unset;
    box-sizing: border-box;
    width: 100vw;
  }

  .merge-page .content-retract {
    padding: 0 16px;
  }

  .merge-page .header {
    padding: 0 24px;
  }

  .merge-page .header .nav-icon {
    padding-right: 20px;
  }

  .merge-page .header .nav-panel {
    z-index: 10000;
    background: #000;
    width: 100%;
    display: none;
    position: absolute;
    top: 88px;
    left: 0;
  }

  .merge-page .header .nav-panel.nav-show {
    display: block;
  }

  .merge-page .header .nav-panel .faqs {
    color: #fff;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 40px;
    font-size: 14px;
    font-weight: 600;
    line-height: 144%;
    text-decoration: none;
    display: flex;
  }

  .merge-page .header .nav-panel .link-con {
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 40px;
    display: flex;
  }

  .merge-page .header .nav-panel .link-con a {
    width: 24px;
    height: 24px;
    margin-left: 24px;
  }

  .merge-page .merge-content {
    box-sizing: border-box;
    margin-bottom: 100px;
    padding: 16px;
  }

  .merge-page .merge-content .merge-title {
    margin-top: 8px;
    font-size: 24px;
    font-weight: 600;
  }

  .merge-page .merge-content .merge-label {
    font-size: 16px;
    font-weight: 500;
  }

  .merge-page .merge-content .merge-select-con {
    flex-wrap: wrap;
    justify-content: center;
  }

  .merge-page .merge-content .merge-select-con .item {
    margin-bottom: 24px;
    margin-right: 0;
  }

  .merge-page .merge-content .merge-succ-con {
    flex-wrap: wrap;
    justify-content: center;
  }

  .merge-page .merge-content .merge-succ-con .item {
    margin-right: 0;
  }

  .merge-page .merge-content .merge-succ-con .right-con {
    margin-left: 0;
  }

  .merge-page .merge-content .btn-group {
    box-sizing: border-box;
    background: #0a0a1e;
    justify-content: space-between;
    width: 100%;
    margin-top: 24px;
    padding: 24px 16px;
    position: fixed;
    bottom: 0;
    left: 0;
  }

  .merge-page .merge-content .btn-group .button {
    width: unset;
    margin-right: unset;
  }

  .merge-page .merge-content .btn-group .button.merge {
    flex: 2.34;
    margin-right: 24px;
  }

  .merge-page .merge-content .btn-group .button.reset {
    flex: 1;
  }
}

/*# sourceMappingURL=merge.9c7f5e57.css.map */
