@font-face {
    font-family: 'Digital Numbers';
    src: url('../../common/font/Digital-Numbers.ttf');
}
html {
    font-family: 'MD IO';
    font-weight: 400;
    font-style: normal;
}
body {
    margin: 0;
    background: #000000;
    color: #fff;
    max-width: 1200px;
    margin: 0 auto;
}
/* 设置滚动条的样式 */
*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    border-radius: 4px;
}
*::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
}
img {
    display: block;
}
.content {
    max-width: 1200px;
    margin: 0 auto;
    overflow-y: scroll;
}
.content-retract {
    padding: 0 32px;
}
.merge-page {
    .header {
        height: 88px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        .left {
            .logo {
                height: 40px;
                display: inline-block;
                vertical-align: bottom;
            }
        }
        .middle {
            display: flex;
            align-items: center;
            justify-content: center;
            .faqs {
                margin-right: 14px;
                font-weight: 600;
                font-size: 14px;
                margin-left: 48px;
                text-decoration: none;
                text-transform: uppercase;
                color: #fff;
                cursor: pointer;
            }
        }
        .right {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .link-con {
                margin-left: 60px;
                display: flex;
                align-items: center;
                .item {
                    margin-left: 20px;
                    svg {
                        height: 13px;
                    }
                }
            }
        }
        .nav-panel {
            display: none;
        }
    }
    .sm-show {
        display: none !important;
    }
    .merge-content {
        .merge-title {
            font-size: 56px;
            font-weight: 700;
            color: #ffffff;
            margin: 32px 0;
        }
        .merge-label {
            font-weight: 500;
            font-size: 16px;
            color: #ffffff;
            margin-bottom: 8px;
            a {
                text-decoration: none;
                color: #797bff;
            }
        }
        .merge-select-con,
        .merge-succ-con {
            margin-top: 40px;
            .item {
                margin-right: 24px;
                .hero-img {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                }
                .hero {
                    font-size: 14px;
                    margin-left: 4px;
                    font-weight: 600;
                    font-size: 14px;
                    color: #ffffff;
                }
                .elite {
                    color: #74f3f5;
                }
                .epic {
                    color: #aa18dd;
                }
                .legendary {
                    color: #d66513;
                }
                .ar {
                    margin-left: 24px;
                }
                .no-img,
                .has-img {
                    margin-top: 8px;
                    width: 240px;
                    height: 240px;
                    background: #22252d;
                    border: 2px dashed #31343f;
                    border-radius: 12px;
                    cursor: pointer;
                }
                .has-img {
                    border-color: transparent;
                    position: relative;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 12px;
                        overflow: hidden;
                    }
                    .close-btn {
                        right: -8px;
                        top: -8px;
                        position: absolute;
                    }
                }
            }
        }
        .merge-succ-con {
            .right-con {
                min-width: 258px;
                margin-left: 76px;
                margin-top: 24px;
                .right-title {
                    font-weight: 600;
                    font-size: 14px;
                    color: #ffffff;
                    margin-bottom: 8px;
                }
                #attr-con {
                    width: 100%;
                    .attr {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 8px;
                        .label {
                            font-style: italic;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 144%;
                            color: #8c8ca2;
                        }
                        .value {
                            font-weight: 600;
                            font-size: 14px;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
        .btn-group {
            margin-top: 48px;
            .button {
                cursor: pointer;
                height: 48px;
                border-radius: 8px;
                margin-right: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                &:active {
                    opacity: 0.85;
                }
                &.merge {
                    background: #797bff;
                    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4);
                    width: 240px;
                    color: #fff;
                    &.disable {
                        opacity: 0.4;
                        cursor: not-allowed;
                    }
                }
                &.reset {
                    width: 100px;
                    background: rgba(121, 123, 255, 0.2);
                    box-shadow: 0px 6px 2px -4px rgba(14, 14, 44, 0.1), inset 0px -1px 0px rgba(14, 14, 44, 0.4);
                    color: #797bff;
                }
            }
        }
    }
}
#waitStatus {
    width: 568px;
    text-align: left;
    margin-top: 58px;
    font-size: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: 10px;
}
.spinner {
    position: fixed !important;
}
#dialog {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    box-sizing: border-box;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
        width: 360px;
        background: #4a4a68;
        padding: 24px;
        .label {
            font-family: 'MD IO 0.4';
            font-style: italic;
            font-weight: 400;
            line-height: 144%;
        }
        .btn-con {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 24px;
            .btn {
                height: 48px;
                flex: 1;
                background: #797bff;
                border-radius: 8px;
                font-size: 16px;
                font-weight: 600;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                &.cancel-btn {
                    background: rgba(121, 123, 255, 0.2);
                    color: #797bff;
                    margin-right: 24px;
                }
            }
        }
    }
}
.hidden {
    display: none !important;
}
// 移动端
@media screen and (max-width: 768px) {
    .merge-page {
        .sm-hide {
            display: none !important;
        }
        .sm-show {
            display: block !important;
        }
        .content,
        body,
        html {
            width: 100vw;
            max-width: unset;
            box-sizing: border-box;
        }
        .content-retract {
            padding: 0 16px;
        }
        .header {
            padding: 0 24px;
            .nav-icon {
                padding-right: 20px;
            }
            .nav-panel {
                display: none;
                position: absolute;
                top: 88px;
                left: 0;
                width: 100%;
                background: #000;
                z-index: 10000;
                &.nav-show {
                    display: block;
                }
                .faqs {
                    padding: 20px 40px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 144%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    color: #fff;
                    text-decoration: none;
                }
                .link-con {
                    padding-right: 40px;
                    padding-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    a {
                        margin-left: 24px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .merge-content {
            padding: 16px;
            box-sizing: border-box;
            margin-bottom: 100px;
            .merge-title {
                margin-top: 8px;
                font-weight: 600;
                font-size: 24px;
            }
            .merge-label {
                font-weight: 500;
                font-size: 16px;
            }
            .merge-select-con {
                flex-wrap: wrap;
                justify-content: center;
                .item {
                    margin-bottom: 24px;
                    margin-right: 0;
                }
            }
            .merge-succ-con {
                flex-wrap: wrap;
                justify-content: center;
                .item {
                    margin-right: 0;
                }
                .right-con {
                    margin-left: 0;
                }
            }
            .btn-group {
                margin-top: 24px;
                background: #0a0a1e;
                width: 100%;
                position: fixed;
                bottom: 0px;
                left: 0;
                padding: 24px 16px;
                box-sizing: border-box;
                justify-content: space-between;
                .button {
                    width: unset;
                    margin-right: unset;
                    &.merge {
                        flex: 2.34;
                        margin-right: 24px;
                    }
                    &.reset {
                        flex: 1;
                    }
                }
            }
        }
    }
}
